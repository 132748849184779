<template>
<div class="footer">
<div class="footerTop">
  <div class="footerMain">
  <div class="colOne">
    <div class="footerPic1">
      <img src="../assets/imgs/home/footer1.png">
    </div>
    科技，创新，定制化需求！
  </div>
    <div class="colTwo">
      <div class="colTitle">
        服务热线
      </div>
      <div class="colYellow">
        0571-88362200
      </div>
      <div class="colTxt">
        咨询时间（9：00-17：30）
      </div>
    </div>
    <div class="colThree">
      <div class="colTitle">联系我们</div>
      <div class="colTxt"> 名称：浙江国通电气科技有限公司</div>
      <div class="colTxt">地址：杭州市余杭区仓前街道欧美金融城2幢606室</div>
      <div class="colTxt"> 电子邮箱：info@gtelec.net</div>
    </div>
    <div class="colFour">
      <div class="colTitle">关于我们</div>
      <div class="wxCode">
        <img src="../assets/imgs/home/wxCode.png">
      </div>
      <div class="codeTxt">微信公众号</div>
    </div>
  </div>
</div>
  <div class="footerBom">
    ©2021 浙江国通电气科技有限公司 版权所有 <a href="https://beian.miit.gov.cn" target="view_window">浙ICP备20006768号-1</a>

  </div>
</div>
</template>

<script>
export default {
  name: "gtFooter"
}
</script>

<style lang="scss" scoped>
.footer{
  //position: relative;
  width: 1920px;
  height: 250px;
}
.footerTop{
  position: relative;
  width: 1920px;
  height: 200px;
  background: #43494E;
}
.footerMain{
  position: relative;
  margin: 0 auto;
  width: 1200px;
  height: 200px;
  padding: 30px 10px;
  box-sizing: border-box;
 display: flex;
  justify-content: space-between;
}
.footerBom{
  position: relative;
  width: 1920px;
  height: 50px;
  background: black;
  font-size: 12px;
  text-align: center;
  line-height: 50px;
  color: white;
  a{
    color: rgb(96, 96, 250) !important;
  }
}
.colOne{
  color: white;
  font-size: 14px;
  min-height: 100px;
  min-width:150px;
}
  .colTwo{
    position: relative;
  color: white;
  font-size: 14px;
  min-height: 100px;
  min-width: 150px;
    .colYellow{
      color: #EEAA3D;
     margin: 10px 0;
    }

  }
.colThree{
  color: white;
  font-size: 14px;
  min-height: 100px;
  min-width: 150px;
  .colTitle{
 margin-bottom: 2px;


  }
  .colTxt{
    margin: 5px 0;
  }
  .colTxt:nth-child(1){
    margin: 10px 0;
  }
}
.colFour{
   color: white;
   font-size: 14px;
   min-height: 100px;
   min-width: 150px;
  .wxCode{
    margin: 10px 0;
  }
  img{
    width: 100px;
    height: 100px;
  }
 }

.footerPic1{
  width: 130px;
  height: 30px;
  margin-bottom: 20px;
  img{
    width: 230px;
    height: 30px;

  }
}
.colTitle{
  display: inline-block;
  position: relative;
  border-bottom: 1px solid white;


}
</style>
