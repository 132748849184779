export const isInViewPort={
    data(){
        return{

        }
    },
    methods:{
        isInViewPort(element) { //检查元素是否在可视区域
            const viewWidth = window.innerWidth || document.documentElement.clientWidth;
            const viewHeight = window.innerHeight || document.documentElement.clientHeight;
            const {
                top,
                right,
                bottom,
                left,
            } = element.getBoundingClientRect();

            return (
                top >= -200 &&
                left >= 0 &&
                right <= viewWidth &&
                bottom <= viewHeight+300
            );
        },
    },
    mounted() {
    }

}
