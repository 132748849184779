<template>
<div class="gtHeader">
<div class="headerMain">
<div class="headerLeft">
  <img src="../assets/imgs/home/gtLogo2.png">
</div>
  <div class="headerCenter">
    <div class="navEver"  @click="toPage('/')">
      <div class="navTxt syTxt">
        首页
        <div class="navAn">
          <div class="navDef" :class="{'navChecked':(checked=='sy')}"></div>
        </div>
      </div>
    <div class="optionLine">

      <div class="optionEver">
        <a href="#coreProducts">
          核心产品
        </a>

      </div>
      <div class="optionEver">
        <a href="#programme">
        行业解决方案
        </a>

      </div>
      <div class="optionEver">
        <a href="#advantage">
        核心优势
        </a>
      </div>
      <div class="optionEver">
        <a href="#choiceMe">
          为什么选择我们

        </a>
      </div>
      <div class="optionEver">
        <a href="#partner">
        合作伙伴

        </a>

      </div>


    </div>

    </div>
    <div class="navEver"  @click="toPage('productCenter')">
      <div class="navTxt">
        产品中心
        <div class="navAn">
          <div class="navDef" :class="{'navChecked':(checked=='cpzx')}"></div>
        </div>
      </div>
<!--      <div class="optionLine">-->

<!--        <div class="optionEver">-->
<!--          公司简介-->

<!--        </div>-->

<!--      </div>-->
    </div>
    <div class="navEver"  @click="toPage('newsCenter')">
      <div class="navTxt">
        新闻中心
        <div class="navAn">
          <div class="navDef" :class="{'navChecked':(checked=='xwzx')}"></div>
        </div>
      </div>
      <div class="optionLine">
        <div class="optionEver">
          <a href="#dynamic">
          公司动态
          </a>
        </div>
        <div class="optionEver">
          <a href="#information">
            精彩资讯
          </a>
        </div>
      </div>
    </div>
    <div class="navEver"  @click="toPage('practical')">
      <div class="navTxt">
        实践案例
        <div class="navAn">
          <div class="navDef" :class="{'navChecked':(checked=='sjal')}"></div>
        </div>
      </div>
<!--      <div class="optionLine">-->

<!--        <div class="optionEver">-->
<!--          公司简介-->

<!--        </div>-->

<!--      </div>-->
    </div>
<!--    <div class="navEver" @click="toPage('afterSale')">-->
<!--      <div class="navTxt">-->
<!--        售后服务-->
<!--        <div class="navAn">-->
<!--          <div class="navDef" :class="{'navChecked':(checked=='shfw')}" ></div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="optionLine">-->

<!--        <div class="optionEver">-->
<!--          公司简介-->

<!--        </div>-->

<!--      </div>-->
<!--    </div>-->
    <div class="navEver"  @click="toPage('business')">
      <div class="navTxt">
        关于我们
        <div class="navAn">
          <div class="navDef" :class="{'navChecked':(checked=='swhz')}"></div>
        </div>
      </div>
      <div class="optionLine">

        <div class="optionEver">
          <a href="#companyProfile">
          公司简介
          </a>
        </div>
        <div class="optionEver">
          <a href="#course">
            公司历程
          </a>
        </div>
        <div class="optionEver">
          <a href="#gtMap">
            联系我们
          </a>
        </div>
      </div>
    </div>
    <div class="navEver"  @click="toPage('personnel')">
      <div class="navTxt">
        人才招聘
        <div class="navAn">
          <div class="navDef" :class="{'navChecked':(checked=='rczp')}"></div>
        </div>
      </div>
      <div class="optionLine">

        <div class="optionEver">
          <a href="#recruitment">
            招聘岗位
          </a>
        </div>
        <div class="optionEver">
          <a href="#concat">
            联系方式
          </a>
        </div>
      </div>
    </div>


  </div>
  <div class="headerRight">
    <div class="phonePic">
      <img src="../assets/imgs/home/phone.png">
    </div>
    咨询热线：0571-88362200 </div>
</div>
</div>
</template>

<script>
export default {
  name: "gtHeader",
  props:{
    checked:String
  },
  methods:{
    toPage(option){
      this.$router.push(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.gtHeader{
  position: relative;
    width: 10rem;
    height: 0.625rem;
    background: #333;
    z-index: 99;
}
.headerMain{
  margin: 0 auto;
    position: relative;
    width: 6.25rem;
    height: 0.625rem;
    padding: 0 0.20833rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.headerLeft{
  position: relative;
    width: 0.52083rem;
    height: 0.57292rem;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 0.05208rem 0.05208rem;
    transform: translateY(-0.02604rem);
  img{
    width: 0.3125rem;
    height: 0.3125rem;
  }
}
.headerCenter{
  width: 3.07292rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: .08333rem;
    margin-left: 0.52083rem;
}
.navEver{
  position: relative;
    margin-top: 0.05208rem;
    border-bottom: 0.02604rem solid transparent;
    cursor: pointer;
    box-sizing: border-box;

}
.navEver:nth-child(1){
  margin-right: 0.07813rem;
}
.navAn{
  margin-top: 0.02604rem;
    width: 100%;
    height: 0.02604rem;
    overflow: hidden;
}
.navChecked{
  //border-bottom: 0.05rem solid #EEAA3D;
  width: 100%;
  height: 0.05rem;
  background: #eeaa3d!important;
    animation: an1-data-v-50458e4c .5s 0s;

}
@keyframes an1 {
  from {transform: translateX(-100%)}
  to{transform: translateX(0)}
}
.navDef{
  width: 100%;
  height: 0.05rem;
  background: #333333 ;

}

.headerRight{
  position: absolute;
    height: 100%;
    top: 0;
    right: 0.36458rem;
    color: #fff;
    font-size: .08333rem;
    display: flex;
    align-items: center;
}
.phonePic{
  img{
    width: 0.14583rem;
    height: 0.14583rem;
    margin-right: 0.07813rem;

  }

}
//.optionLine:nth-child(1){
//position:absolute;
//  width: 1rem;
//  height:0rem;
//  overflow: hidden;  //ad
//  transform: translateX(-0.3rem);
//  background: rgba(255,255,255,0.5);
//  border-radius: 0.06rem;
//  transition: 0.2s;
//}
.optionLine{
  position:absolute;
  width: 1rem;
  //height:0rem;
  overflow: hidden;  //ad
  background: rgba(255,255,255,0.5);
  border-radius: 0.06rem;
  transition: 0.2s;
  transform: scale(0);
  left: -0.1rem;
  transform-origin: top;
  opacity: 0;

}
.optionLine:nth-child(1){
  position:absolute;
  width: 1rem;
  height:0rem;
  overflow: hidden;  //ad
  transform: translateX(0rem);
  background: rgba(255,255,255,0.5);
  border-radius: 0.06rem;
  transition: 0.2s;

  left: -0.35rem !important;

}
.navEver:hover .optionLine{
  width: 0.52083rem;
    transform: scale(1);
    position: absolute;
    overflow: hidden;
    background: hsla(0,0%,100%,.5);
    border-radius: 0.03125rem;
    transition: .4s;
    opacity: 1;
}
.coverLine{
  //position: absolute;
  //width: 1rem;
  //height: 2rem;
  //background: white;
  //box-shadow: inset 0 0 0 0.8rem rgba(222,222,222,1);
  //filter: blur(0.5rem);
  //text-align: center;
  //color: white;

}
.optionEver{
    width: 100%;
    text-align: center;
    min-height: 0.20833rem;
    line-height: .20833rem;
}
.optionEver:hover{
  background: rgba(0,0,0,0.2);
  transition: 0.3s;
}
.syTxt{
  margin-left: 0.08854rem;
}

</style>
