<template>
<div class="practical" ref="practical"  >
  <gt-header checked="sjal"></gt-header>
  <cust-case></cust-case>
  <gt-footer></gt-footer>

</div>
</template>

<script>
import GtHeader from "../../components/gtHeader";
import GtFooter from "../../components/gtFooter";

import  {isInViewPort} from "../../mixin/mixin";
import CustCase from "../../components/nPractical/custCase";

export default {
  name: "practical",
  components: {CustCase, GtFooter, GtHeader},
  mixins:[isInViewPort],
  data(){
    return{

    }
  },
  methods:{


  }
}
</script>

<style lang="scss" scoped>
.practical{
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar{
    width:10px;
  }
  //滚动条小方块
  &::-webkit-scrollbar-thumb{
    border-radius:5px;
    background:rgb(167, 198, 156);
  }
  //滚动条轨道
  &::-webkit-scrollbar-track{
    border-radius:0px;
    height:100px;
    background:rgba(167, 198, 156,0.3);
  }
}
.toTop{
  position: fixed;
  bottom: 200px;
  right: 200px;
  width: 60px;
  height: 60px;
  background: #EAEAEA;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  .topArrow{
    cursor: pointer;
    color: white;
    font-size: 40px;
  }
}


</style>
