<template>
<div class="custCase">
  <div class="titleLine">
    <div class="lineOne">
      <img src="../../assets/newImgs/nPractical/picTitle.png" alt="">
    </div>
    <div class="lineTwo">
      <img src="../../assets/newImgs/home/leftsjx.png" alt="">
      <div>客<span>户案</span>例</div>
      <img src="../../assets/newImgs/home/rightsjx.png" alt="">

    </div>
  </div>
  <div class="mainCenter">
  <div class="mainLeft">
    <img src="../../assets/newImgs/nPractical/pic1.png" alt="">
  </div>
    <div class="mainRigth">
      <div class="line">
      <img class="midImg" src="../../assets/newImgs/nPractical/pic2.png" alt="">
      <img class="longImg" src="../../assets/newImgs/nPractical/pic3.png" alt="">
      <img class="midImg" src="../../assets/newImgs/nPractical/pic4.png" alt="">
        <div class="coverPic">
          <div class="coverTitle">用心做服务</div>
          <div class="coverTxt">service</div>
        </div>

      </div>
      <div class="line">

      <img class="longImg" src="../../assets/newImgs/nPractical/pic5.png" alt="">
      <img class="midImg" src="../../assets/newImgs/nPractical/pic6.png" alt="">
      <img class="midImg" src="../../assets/newImgs/nPractical/pic7.png" alt="">
        <div class="coverPic2">
          <div class="coverTitle">用品质来说话</div>
          <div class="coverTxt">quality</div>
        </div>

      </div>

    </div>
  </div>
  <div class="custBom">
    <div class="colEver">
    <div class="areaEver">
      <div class="areaIcon">
        <img src="../../assets/newImgs/nPractical/zhengfu.png" alt="">
      </div>
      <div class="areaTxt">政府机关</div>
    </div>
      <div class="areaEver">
        <div class="areaIcon">
          <img src="../../assets/newImgs/nPractical/qiye.png" alt="">
        </div>
        <div class="areaTxt">国有企业</div>
      </div>
    </div>
    <div class="colEver">
    <div class="areaEver">
      <div class="areaIcon">
        <img src="../../assets/newImgs/nPractical/dasha.png" alt="">
      </div>
      <div class="areaTxt">商务大厦</div>
    </div>
      <div class="areaEver">
        <div class="areaIcon">
          <img src="../../assets/newImgs/nPractical/dazhuan.png" alt="">
        </div>
        <div class="areaTxt">大专院校</div>
      </div>
    </div>
    <div class="colEver">
    <div class="areaEver">
      <div class="areaIcon">
        <img src="../../assets/newImgs/nPractical/yuanqu.png" alt="">
      </div>
      <div class="areaTxt">产业园区</div>
    </div>
    <div class="areaEver">
      <div class="areaIcon">
        <img src="../../assets/newImgs/nPractical/yiyuan.png" alt="">
      </div>
      <div class="areaTxt">医院养老</div>
    </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "custCase"
}
</script>

<style lang="scss" scoped>
.custCase{
  position: relative;
  width: 1920px;
  height: 800px;
  background: white;
  padding-top: 60px;
  box-sizing: border-box;
}
.titleLine{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .lineOne{
    img{
      width: 268px;
    }
   
  }
 
}
.lineTwo{
  display: flex;
  color: #A7C69C;
  font-size: 36px;
  align-items: center;
span{
  border-bottom: 4px solid #EEAA3D;
}
 img{
        width: 45px;
      }
}
.mainCenter {
  position: relative;
  width: 1051px;
  height: 365px;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;

  .mainLeft {
    position: relative;
    width: 244px;
    height: 365px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .mainRight {
    position: relative;
    width: 807px;
    height: 365px;
  }
  .line{
    position: relative;
    width: 807px;
    height: 182px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.midImg{
  position: relative;
width: 194px;
  height: 182px;

  vertical-align: bottom;
  display:inline-block;

}
.longImg{
  position: relative;
 width: 419px;
  height: 182px;

  display:inline-block;
}
.coverPic{
  position: absolute;
  left: 0;
  top: 0;
  width: 194px;
  height: 182px;
  background: rgba(0,0,0,0.5);
  padding:15px;
  box-sizing: border-box;
  .coverTitle{
   color: white;
    font-size: 24px;
  }
  .coverTxt{
   color: white;
    font-size: 14px;
    margin-top: 10px;
  }
}
.coverPic2{
  position: absolute;
  right: 194px;
  top: 0;
  width: 194px;
  height: 182px;
  background: rgba(0,0,0,0.5);
  padding:15px;
  box-sizing: border-box;
  .coverTitle{
    color: white;
    font-size: 24px;
  }
  .coverTxt{
    color: white;
    font-size: 14px;
    margin-top: 10px;
  }
}
.custBom{
  position: relative;
  width: 1000px;
  height: 150px;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  .areaEver{
    position: relative;
    height: 50px;
    display: flex;
    line-height: 50px;
    min-width: 100px;
    margin-top: 20px;
    .areaIcon{
      width: 47px;
      height: 47px;
      img{
        width: 47px;
        height: 47px;
      }
    }
    .areaTxt{
      color: #A7C69C;
      font-size: 24px;
      white-space: nowrap;
    }
  }
}
.colEver{
  position: relative;
  height: 150px;
}
</style>
